import React from 'react';
import {Choice } from 'components';
import styled, { css } from 'react-emotion';

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
    h1{
      font-size: 6em;
      margin-bottom: 0.3em;
    }
`;

const Index = () => (
  <Choice
    first="designer"
    firstTo="/digitalorphysical"
    second="developer"
    secondTo="/developer"
  >
    <Title>
      <h1>Aiden Kwok</h1>
    </Title>
  </Choice>
);

export default Index;